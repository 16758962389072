import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import Inicio from '../views/Inicio.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/portafolio',
    name: 'portafolio',
    component: () => import('../views/portafolio.vue')
  },
  {
    path: '/contactanos',
    name: 'contactanos',
    component: () => import('../views/contactanos.vue')
  }
]

const router = new VueRouter({
  mode: '',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
