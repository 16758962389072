<template>
  <v-app>
    <v-container fluid>
      <v-card
    class="mx-auto"
    max-width="1000"
    height="400"
  >
      <Slider :onClick="VerDetalleComercio"/>
</v-card>
      <br>
      <v-row justify="center" class="text-center">
          <h3 class="display-2 font-weight-bold">CATEGORIAS</h3>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-item-group mandatory class="mt-n1">
    <v-container>
      <v-row justify="center" class="space">
        <v-col
          cols="12"
          xs="12"
          sm="4"
          md="2"
          v-for="(cetegory, i) in categories"
          :key="i"
          @click="() => VerComercio(cetegory.id_categoria)"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? '#D5F0DB' : 'white'"
              :class="active ? 'borderme' : 'borderout'"
              class="d-flex align-center rounded-lg mx-2"
              dark
              height="100"
              @click="toggle"
              flat
               elevation="10"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-list-item three-line class="text-center">
                    <v-list-item-content>
                      <div align="center" justify="center">
                        <!-- <v-img
                          :src="cetegory.img"
                          max-height="80"
                          max-width="80"
                          contain
                        ></v-img> -->
                        <v-icon left size="60" color="green">mdi-{{cetegory.icono}}</v-icon>
                      </div>
                      <v-list-item-subtitle
                        :class="active ? 'green--text' : 'black--text'"
                        class="caption mt-4"
                        >{{ cetegory.nombre }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
  <br>
          <!-- <v-row v-show="ocultarcategoria == false"  justify="center"> -->
          <v-row  justify="center">
        <v-col cols="12" sm="4" md="4">
                      <v-text-field
                      color="green darken-2"
                      v-model="buscarnegocio"
                      @keypress.enter="buscador(buscarnegocio)"
                        label="Buscar Negocio"
                        dense
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
      </v-row>
          <v-row v-show="ocultarcategoria == true"  justify="center">
          <!-- <v-row   justify="center"> -->

       <v-btn color="secondary" elevation="2" @click="cambiarb()">Cambiar Busqueda</v-btn>
      </v-row>
      <v-alert :value="alerta" elevation="24" shaped type="info" transition="slide-y-transition"> Por Favor Ingresar Un Nombre Para Buscar</v-alert>
          <v-card v-show="verdatos == true" flat color="#D5F0DB" class="rounded-xl mt-8">
          <!-- <v-card  flat color="#D5F0DB" class="rounded-xl mt-8"> -->
            <v-row justify="center" class="text-center">
          <h3 class="display-2 font-weight-bold">NEGOCIOS</h3>
      </v-row>
            <v-row v-show="ocultarcategoria == true" justify="center">
            <!-- <v-row  justify="center"> -->
        <v-col cols="12" sm="4" md="4">
                      <v-text-field
                       background-color="white"
                      color="green darken-2"
                      v-model="buscarnombre"
                      @keypress.enter="search(buscarnombre)"
                        label="Buscar en Categoria"
                        dense
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
      </v-row>
            <v-row justify="center" class="space">
    <v-col
    cols="12" sm="3" md="3"
      v-for="(com, i) in todosloscomercios"
      :key="i"
    >
      <v-card class="mx-auto rounded-xl" max-width="300" color="" flat outlined>
        <div align="center" justify="center">
          <v-img
            :aspect-ratio="16/9"
            width="300"
            :src="com.imagen"
          ></v-img>
        </div>

        <v-card-title class="">{{ com.nombre_negocio }}</v-card-title>
        <v-card-title class="grey--text text-grey-darken-1 caption mt-n6">{{
          com.eslogan
        }}</v-card-title>
        <v-card-title class="mt-n4"></v-card-title>
        <v-row class="justify-center">
             <v-card-actions class="mx-2 mt-n4">
         <v-btn rounded color="success" dark @click="VerDetalleComercio(com.id_negocio)">Ver Mas Detalle</v-btn>
        </v-card-actions>
          </v-row>
      </v-card>
    </v-col>
  </v-row>
          </v-card>

          <v-card
            flat
            color="#E2F2E5"
            class="rounded-xl mtop mbottom mx-10 pa-4"
            height="350"
          >
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="3">
                <v-card
                  class="rounded-xl mt-n16 ml-16 mobile"
                  outlined
                  flat
                  height="430"
                  width="200"
                >
                  <v-img src="mobile2.jpeg"></v-img>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <h1 class="mt-16">Descarga Nuestra App</h1>
                <p>
                  Si No queires ingresar por el Navegador tranquilo tambien tenemos acceso a tu plataforma tu plaza polonuevo
                  desde nuestra app que la puedes descargar desde la play store.
                </p>
                <!-- <v-btn color="black" dark class="mb-10">
                  <v-icon color="white" left>fab fa-apple</v-icon>
                  App Store
                </v-btn> -->
                <v-btn href="https://play.google.com/store/apps/details?id=com.grupof23.tu_plaza_flutter" target="_blank" color="black" dark class="ml-4 mb-10">
                  <v-icon color="white" left>fab fa-google-play</v-icon>
                  Play Store
                </v-btn>
              </v-col>
               <v-col cols="12" xs="12" sm="12" md="3">
                <v-card
                  class="rounded-xl mt-n16 ml-16 mobile"
                  outlined
                  flat
                  height="430"
                  width="200"
                >
                  <v-img src="mobile1.jpeg"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
       <!-- ventana de diálogo registros -->
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar color="success" dark>{{detallen.nombre_negocio}}
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-card
    class="mx-auto"
    max-width="1000"
    height="400"
  >
   <v-carousel cycle  height="400" hide-delimiter-background show-arrows-on-hover>
    <template v-slot:prev="{ on, attrs }">
      <v-btn color="success" v-bind="attrs" v-on="on"><v-icon>
        mdi-chevron-double-left
      </v-icon></v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn color="success" v-bind="attrs" v-on="on"><v-icon>
        mdi-chevron-double-right
      </v-icon></v-btn>
    </template>
    <v-carousel-item v-for="detalle in detalleimagenes" :key="detalle.ruta">
      <v-img height="400" :src="detalle.ruta"></v-img>
    </v-carousel-item>
  </v-carousel>
  </v-card>
   <section class="intro-section" id="home">
                <v-row class="align-center px-10">
                    <v-col lg="12" cols="12" class="font-weight-bold">
                        <h2 class="display-3">{{detallen.nombre_negocio}}</h2>
                        <v-subheader class="text-subtitle-2">{{detallen.eslogan}}</v-subheader>
                        <br>
                    </v-col>
                </v-row>
            </section>
 <v-row>
                            <v-col cols="12" xs="6" sm="6" md="6">
                               <v-card-title>CONTACTO</v-card-title>
                                <v-card-text class="text-subtitle-2">
                                    <strong> <v-icon>mdi-google-maps</v-icon></strong>{{detallen.direccion}}
                                    <strong> Barrio:</strong>{{detallen.barrio}}<br>
                                    <strong> <v-icon>mdi-cellphone</v-icon></strong>{{detallen.celular1}}<br>
                                    <strong> <v-icon>mdi-cellphone</v-icon></strong>{{detallen.celular2}}<br>
                                </v-card-text>
                                <v-row class="justify-center">
                                <v-btn class="ma-2" rounded color="primary" :href="`https://www.facebook.com/${detallen.facebook}/`" target="_blank"><v-icon left size="20">mdi-facebook</v-icon>
                              {{detallen.facebook}}</v-btn>
                              <v-btn class="ma-2" rounded color="success" :href="`https://api.whatsapp.com/send?phone=${detallen.whatsapp}`" target="_blank"><v-icon left size="20">mdi-whatsapp</v-icon>
                              {{detallen.whatsapp}}</v-btn>
                                <v-btn class="ma-2" rounded color="error" :href="`https://www.instagram.com/${detallen.instagram}/`" target="_blank"><v-icon left size="20">mdi-instagram</v-icon>
                              {{detallen.instagram}}</v-btn>
                              <v-btn v-show="detallen.vermenu != 0" class="ma-2" rounded color="primary" :href="detallen.vermenu" target="_blank">Menu
                                        <v-icon>mdi-eye</v-icon></v-btn>
                        </v-row>
                            </v-col>
                            <v-col cols="12" xs="6" sm="6" md="6">
                                <v-alert
                                  border="right"
                                  colored-border
                                  color="green accent-4"
                                  elevation="2"
                                >
                  {{detallen.descripcion_negocio}}
                              </v-alert>
                            </v-col>
                        </v-row>
          <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" elevation="8" small @click="dialog = false"
                >Atras</v-btn
              >
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import Slider from "../components/sliders/slider.vue";
import axios from "axios";
// import negociosjson from "../assets/json/negocios.json";
export default {
  data: () => ({
    
    buscarnegocio: "",
    alerta: false,
    verdatos:false,
    ocultarcategoria:false,
    categories: [],
    buscarnombre:"",
    todosloscomercios: [],
    // todosloscomercios: negociosjson,
    comerciosbuscar: [],
    dialog: false,
    detallen:[],
    detalleimagenes:[],
    tipo_dispositivo:"",
ip_dispositivo:""
  }),
  components: {
    Slider,
  },
  created(){
    this.SaberIp();
this.ListaCategorias();
  },
  methods:{
    ListaCategorias(){
       axios.get("publico/categorias").then(
          function (response) {
            this.categories = response.data;
          }.bind(this)
        );
    },
    cambiarb(){
      this.ocultarcategoria = false;
      this.verdatos = false;
      this.todosloscomercios = [];
      this.buscarnegocio = "";
    },
     VerComercio: function (codigo) {
      axios.get(`publico/vistacomercio/${codigo}`).then(
        function (response) {
          this.verdatos = true;
          this.ocultarcategoria = true;
          this.todosloscomercios = response.data;
          this.comerciosbuscar = response.data;
        }.bind(this)
      );
    },
     VerDetalleComercio: function (codigo) {
      this.ConteoPorNegocio(codigo);
      axios.get(`publico/detallecomercio/${codigo}`).then(
        function (response) {
          this.detallen = response.data[0];
          this.detalleimagenes = JSON.parse(response.data[0].verimagenes);
          this.dialog = true;
        }.bind(this)
      );
    },
     buscador: function (nombre) {
       window.setInterval(() => {
         this.alerta = false;
         }, 4000) 
      if(nombre =="" || nombre==null || nombre==undefined){
        this.alerta = true;
      } else {
      axios.get(`publico/buscarpornombre/${nombre}`).then(
        function (response) {
          this.verdatos = true;
          this.ocultarcategoria = false;
          this.todosloscomercios = response.data;
        }.bind(this)
      );
      }
    },
    search (term) {
      this.resetPosts()
      this.todosloscomercios = this.todosloscomercios.filter((post) => {
        return post.nombre_negocio.toLowerCase().includes(term.toLowerCase())
      })
    },
    resetPosts () {
      this.todosloscomercios = this.comerciosbuscar;
    },
   SeleccionarLocal(local) {
    console.log(local)
      this.dialog = true;
    },

    ListaSedes: function () {
        axios.get("sedes/listar").then(
          function (response) {
            this.listadosedes = response.data;
          }.bind(this)
        );
      },
    SaberIp: function () {
        axios.get("https://api.ipify.org?format=json").then(
          function (response) {
            this.ip_dispositivo = response.data.ip;
            var sBrowser, sUsrAg = navigator.userAgent;

if(sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome";
} else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
} else if (sUsrAg.indexOf("Opera") > -1) {
    sBrowser = "Opera";
} else if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
} else if (sUsrAg.indexOf("MSIE") > -1) {
    sBrowser = "Microsoft Internet Explorer";
}
if (navigator.userAgent.match(/Android/i) ){
 this.tipo_dispositivo = "Android";
}else if (navigator.userAgent.match(/Android/i)){
this.tipo_dispositivo = "Android";
}else if (navigator.userAgent.match(/webOS/i)){
this.tipo_dispositivo = "webOS";
}else if (navigator.userAgent.match(/iPhone/i)){
this.tipo_dispositivo = "iPhone";
}else if (navigator.userAgent.match(/iPod/i)){
this.tipo_dispositivo = "iPod";
}else if (navigator.userAgent.match(/iPad/i)){
this.tipo_dispositivo = "iPad";
}else if (navigator.userAgent.match(/BlackBerry/i)){
this.tipo_dispositivo = "BlackBerry";
} else {
  this.tipo_dispositivo = "PC";
}
        var datos = {
              tipo_dispositivo: this.tipo_dispositivo,
              navegador: sBrowser,
              ip_dispositivo: this.ip_dispositivo
            }
        axios.post("publico/conteo", datos)
          .then((respuesta) => {
          var msg = respuesta;
          msg
          })
          }.bind(this)
        );
      },
    ConteoPorNegocio: function (negocio) {
        axios.get("https://api.ipify.org?format=json").then(
          function (response) {
            this.ip_dispositivo = response.data.ip;
            var sBrowser, sUsrAg = navigator.userAgent;

if(sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome";
} else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
} else if (sUsrAg.indexOf("Opera") > -1) {
    sBrowser = "Opera";
} else if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
} else if (sUsrAg.indexOf("MSIE") > -1) {
    sBrowser = "Microsoft Internet Explorer";
}
if (navigator.userAgent.match(/Android/i) ){
 this.tipo_dispositivo = "Android";
}else if (navigator.userAgent.match(/Android/i)){
this.tipo_dispositivo = "Android";
}else if (navigator.userAgent.match(/webOS/i)){
this.tipo_dispositivo = "webOS";
}else if (navigator.userAgent.match(/iPhone/i)){
this.tipo_dispositivo = "iPhone";
}else if (navigator.userAgent.match(/iPod/i)){
this.tipo_dispositivo = "iPod";
}else if (navigator.userAgent.match(/iPad/i)){
this.tipo_dispositivo = "iPad";
}else if (navigator.userAgent.match(/BlackBerry/i)){
this.tipo_dispositivo = "BlackBerry";
} else {
  this.tipo_dispositivo = "PC";
}
        var datos = {
              tipo_dispositivo: this.tipo_dispositivo,
              navegador: sBrowser,
              negocio: negocio,
              ip_dispositivo: this.ip_dispositivo
            }
        axios.post("publico/conteonegocio", datos)
          .then((respuesta) => {
          var msg = respuesta;
          msg
          })
          }.bind(this)
        );
      },
  },
  computed: {
		
	}
};
</script>
<style >
.marginLeft {
  margin-left: -90px;
}
.mtop {
  margin-top: 100px;
}
.mbottom {
  margin-bottom: 100px;
}
.v-card.borderme {
  border: 2px solid green !important;
}
.v-card.borderout {
  border: 1px solid #d5f0db !important;
}
.v-btn:not(.v-btn--round).v-size--default.add {
  min-width: 0px !important;
}
.theme--light.v-sheet--outlined.mobile {
  border: 2px solid black !important;
}
@media only screen and (max-width: 600px) {
  h2.title1 {
    font-size: 15px;
  }
  h2.title2 {
    font-size: 15px;
  }
  .top {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 600px) {
  .top {
    margin-top: 70px;
  }
}
@media only screen and (min-width: 768px) {
  .top {
    margin-top: 120px;
  }
}
</style>