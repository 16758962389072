<template>
  <!-- <v-app-bar app elevation="0" color="green">
    <v-img
    contain
          style="top: 3px"
          height="50"
          max-width="220"
          src="../assets/logo_nuevo.png"
        ></v-img>
            <v-tabs  right >
                <v-tab class="white--text" :to="'/'">Inicio</v-tab>
                <v-tab class="white--text" :to="'/portafolio'">Portafolio</v-tab>
                <v-tab class="white--text" :to="'/contactanos'">Contactanos</v-tab>
            </v-tabs>
        </v-app-bar> -->

          <v-card color="green">
    <v-img
    contain
          height="100"
          max-width="400"
          src="../assets/logo_nuevo.png"
        ></v-img>
    <!-- <v-toolbar
      color="green"
      dark
      flat
    > -->
      <!-- <template v-slot:extension> -->
        <v-tabs
          v-model="tab"
          centered
      dark
      icons-and-text
     background-color="transparent"
        >
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab :to="'/'">Inicio <v-icon>mdi-home</v-icon></v-tab>
          <v-tab :to="'/portafolio'">Portafolio <v-icon>mdi-laptop</v-icon></v-tab>
          <v-tab :to="'/contactanos'">Contactanos <v-icon>mdi-phone</v-icon></v-tab>
        </v-tabs>
      <!-- </template> -->
    <!-- </v-toolbar> -->

    <!-- <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <Inicio/>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <Portafolio/>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <Contactanos/>
      </v-tab-item>
    </v-tabs-items> -->
  </v-card>
</template>

<script>
// import Inicio from "../views/Inicio.vue";
// import Portafolio from "../views/portafolio.vue";
// import Contactanos from "../views/contactanos.vue";
export default {
  components: {
    // Inicio,
    // Portafolio,
    // Contactanos,
  },
   data: () => ({
        tab: null,
  }),
   created() {
  
  },
methods:{
  
}
};
</script>

<style>
.v-toolbar__title {
  font-size: 1rem !important;
}
</style>