<template>
  <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover>
    <template v-slot:prev="{ on, attrs }">
      <v-btn color="success" v-bind="attrs" v-on="on"><v-icon>
        mdi-chevron-double-left
      </v-icon></v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn color="success" v-bind="attrs" v-on="on"><v-icon>
        mdi-chevron-double-right
      </v-icon></v-btn>
    </template>
    <v-carousel-item v-for="slide in listadobanner" :key="slide.id_banner">
      <a @click="onClick(slide.id_negocio)">
      <v-img 
     height="400"
      :src="slide.ruta"></v-img>
      </a>
    </v-carousel-item>
  </v-carousel>
  <!-- ------------------------------------------------------- -->
</template>
<script>
import axios from "axios";
export default {
   props: {
    onClick: Function,
    },
  data() {
    return {
      listadobanner: [],
    };
  },
  created() {
    this.ListarBanner();
  },
  methods: {
    ListarBanner: function () {
        axios.get("publico/listarbanner/S").then(
          function (response) {
            this.listadobanner = response.data;
          }.bind(this)
        );
      },
  },
};
</script>