<template>
  <v-footer dark padless>
    <v-card flat tile class="black white--text py-12 px-5" width="100%">
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4" class="pa-16">
       <v-img
    contain
          height="60"
          max-width="240"
          src="../assets/logo_nuevo.png"
        ></v-img>
          <v-card-text class="pl-13">
            <v-btn
              v-for="icon in icons"
              :key="icon.url"
              class="mx-1 white--text"
              icon
              fab
              outlined
              small
              :href="icon.url" target="_blank"
            >
              <v-icon size="30px">
                {{ icon.icono }}
              </v-icon>
            </v-btn>
          </v-card-text>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3">
          <v-card-text class="white--text pt-0">
            <h3>Acerca de</h3>
          </v-card-text>
          <v-card-text class="grey--text"> 24/7</v-card-text>
          <v-card-text class="grey--text mt-n4"> Confiable </v-card-text>
          <v-card-text class="grey--text mt-n4"> Accesible desde Cualquier parte </v-card-text>
          <v-card-text class="grey--text mt-n4"> Seguro </v-card-text>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3">
          <v-card-text class="white--text pt-0">
            <h3>Negocios</h3>
          </v-card-text>
          <v-card-text class="grey--text"> Cualquier Negocio </v-card-text>
          <v-card-text class="grey--text mt-n4"> Posicionate </v-card-text>
          <v-card-text class="grey--text mt-n4"> Mas que Un Negocio </v-card-text>
          <v-card-text class="grey--text mt-n4"> Estamos Para Brindarte Ayuda </v-card-text>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="6" md="4">
          <v-card-text class="white--text pt-0">
            <h3>Contacto</h3>
          </v-card-text>
          <v-card-text class="grey--text"> Yordis Escorcia </v-card-text>
          <v-card-text class="grey--text mt-n4">
            Desarrollador De Software
          </v-card-text>
          <v-card-text class="grey--text mt-n4"> yordis.com </v-card-text>
        </v-col> -->
      </v-row>
      <v-row class="justify-center">
        <div>{{ new Date().getFullYear() }} — <strong>GrupoF23</strong></div>
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      // {icono:"mdi-facebook",
      // url:"https://www.facebook.com/"},
      {icono:"mdi-whatsapp",
      url:"https://api.whatsapp.com/send?phone=3108998360"},
      // {icono:"mdi-instagram",
      // url:"https://www.instagram.com/"}
    ],
  }),
};
</script>

<style>
</style>